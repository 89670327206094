import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaGift, FaMinus, FaPlus, FaSpinner, FaTrash } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import { useModal } from '../../../../../../hooks/Modal';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import { Container, ProductItemStyle } from './styles';



interface IProductRender {
    cart: Array<Record<string, any>>;
    product: Record<string, any>;
    setCallback: Function;
    unique: boolean;
    notChange: boolean;
    minimum?: number;
    coupon?: Record<string, any>;

}

interface IAddProductComponent {
    cart: Array<Record<string, any>>;
    setCallback: Function;
    unique: boolean;
    showAdditional?: boolean;
    coupon?: Record<string, any>;
}

function useProducts({ project_id }) {

    const loadProducts = async () => {

        const products = await api.get(`/products-list/${project_id}`, { params: { limitItems: 10000 } });
        products?.data?.rows?.sort((a, b) => a?.price < b?.price ? -1 : a?.price > b?.price ? 1 : 0)
        return products?.data?.rows || [];

    }

    return { loadProducts };

}

const ProductItem: React.FC<IProductRender> = ({ minimum = 0, notChange, unique, product, setCallback, cart, coupon }) => {
    const { product_hash } = useParams<Record<string, any>>();
    const productReference = cart?.find(item => item?._id?.toString() === product?._id?.toString());
    const { addModal } = useModal();
    const couponItem = coupon?.products?.find(i => i?.product?.toString() === product?._id?.toString());


    return product?.title ? <div
        style={{
            borderRadius: '5px',
            padding: '10px 15px',
            border: '1px solid #ddd',
            width: '100%'
        }}
    > <ProductItemStyle style={{ opacity: product?.available_quantities < 1 ? 0.4 : 1 }} >

            <div className='addProductInfo' ><p style={{ fontWeight: 'bold', fontSize: '14px', width: '100%' }}>{product?.title} </p>
                {product_hash !== 'courtesy' && product?.before_price ? <p style={{ position: 'relative', fontWeight: 'bold' }}> {product_hash !== 'courtesy' && product?.before_price && product?.available_quantities >= 1 ? <><div style={{ height: '2px', opacity: 0.5, background: '#777', width: '70px', position: 'absolute', top: '50%', right: '0px' }} /> De:</> : <></>}  R$ {format(product?.before_price)}</p> : <></>}
                {product_hash !== 'courtesy' ? <p style={{ position: 'relative' }}> {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <div style={{ height: '2px', opacity: 0.5, background: '#777', width: '70px', position: 'absolute', top: '50%', right: '0px' }} /> : <>Valor un.:</>}  R$ {format(product?.price)}</p> : <></>}
                {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <p style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '14px', color: 'green' }}>{`R$ `} {format(couponItem?.price)}</p> : <></>}

            </div>



            <div>
                <div className='addProductAction'> {product?.available_quantities < 1 ? <p style={{ color: 'rgb(150,0,0)' }}>Esgotado</p> : <><div><p>{productReference?.quantity || '0'}</p></div>


                    {unique ? <div className={notChange ? 'activeProduct' : productReference?.quantity > 0 ? 'activeProduct changeble' : 'inactiveProduct changeble'}>

                        {notChange ? <FaCheck /> :

                            (!productReference || productReference?.quantity < 1) ? <FaCheck /> : <> <FaCheck className='showIcon' /> <FiX className='notShowIcon' /> </>}
                    </div> : <>
                        <div onClick={() => setCallback(product, 'add')}> <FaPlus style={{ opacity: !productReference || productReference?.quantity >= product?.available_quantities ? 0.5 : 1 }} /> </div>
                        <div onClick={() => !productReference || productReference?.quantity <= minimum ? false : setCallback(product, 'remove')}>  <FaMinus style={{ opacity: !productReference || productReference?.quantity <= minimum ? 0.5 : 1 }} /> </div>

                    </>}</>}
                </div>
                {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '5px', fontSize: '9px', color: 'green', border: '1px solid green', borderRadius: '5px', padding: '5px' }}>Desconto aplicado</p> : <></>}
            </div>


        </ProductItemStyle >
        {product?.benefits_title && product?.benefits_description ? <p onClick={() => product?.benefits_title && product?.benefits_description ? addModal({ title: 'Benefícios', theme: 'whiteModalMedium', content: <p dangerouslySetInnerHTML={{ __html: product?.benefits_description }} /> }) : false} className='scale' style={{ width: '100%', opacity: product?.available_quantities < 1 ? 0.4 : 1, color: '#777', fontSize: '10px', padding: '10px 10px', cursor: 'pointer', paddingBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} ><FaGift style={{ width: '15px', minWidth: '15px' }} /> <span>{product?.benefits_title}</span></p> : <></>}

    </div> : <></>




}

const AddProductsComponent: React.FC<IAddProductComponent> = ({ cart, setCallback, unique = true, showAdditional = true, coupon }) => {
    const { projectHash } = useConfiguration();
    const { product_hash } = useParams<Record<string, any>>()
    const [products, setProducts] = useState<Array<Record<string, any>>>([])

    const { loadProducts } = useProducts({ project_id: projectHash });



    const { search } = useLocation();
    const params = new URLSearchParams(search);




    const [loading, setLoading] = useState(true);

    useEffect(() => {

        loadProducts().then(productsArray => {
            setProducts([...productsArray]);
            setLoading(false);

            const productAdd = params.get("produto");
            console.log(productAdd, productsArray)
            if (productAdd) {
                const productInfo = productsArray?.find(i => i?.hash_link === productAdd);

                if (productInfo) {
                    setCallback(productInfo, 'add')
                }
            }



        });

    }, [])


    const { translate } = useLanguage();

    const listProducts = products?.filter((product) => (product?.status_id === 'Sim'));

    return <Container  >


        {listProducts?.length > 0 ? <h2 style={{ margin: ' 0px', fontSize: '14px', color: '#333', borderBottom: '3px solid #0a4f4b', padding: '5px 0px', width: '100%' }}>{translate('1. Selecione seus ingressos')}</h2> : <></>}

        {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

        {listProducts?.map((product) => <ProductItem coupon={coupon} notChange={false} unique={unique} cart={cart} key={product?._id?.toString()} product={product} setCallback={setCallback} />)}

    </Container >

}

export default AddProductsComponent;
