import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const EventsAdmin: React.FC = () => {
  const endpoint = '/global';
  const title = 'Geral da Plataforma';

  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    description: { ref: 'description', column: 'description', label: 'Nome do evento' },
    logo: { ref: 'logo', column: 'logo', label: 'Logo', show: false },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'descritivo' },

    slides: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'slides',
      label: 'Slides da Capa',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Descritivo', name: 'description' },
        { label: 'Link', name: 'link_url' },
        { label: 'Tipo 1 Quadrado, 2 Retangular', name: 'type' },
      ],
    },
    menu: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'menu',
      label: 'Menu',
      list: {
        title: { label: 'Título', name: 'title' },
        target: { label: 'Link', name: 'target' },
        targetID: { label: 'ID alvo', name: 'targetID' },
        fluid: { label: 'Fluido (rolar)', name: 'fluid', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
        external: { label: 'É Link externo?', name: 'external', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
        status: { label: 'Ativo?', name: 'status', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
      },
    },
    home_buttons: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'home_buttons',
      label: 'Botões na Capa',
      list: {
        title: { label: 'Título', name: 'title' },
        target: { label: 'Link', name: 'target' },
        targetID: { label: 'ID alvo', name: 'targetID' },
        fluid: { label: 'Fluido (rolar)', name: 'fluid', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
        external: { label: 'É Link externo?', name: 'external', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
        status: { label: 'Ativo?', name: 'status', type: 'select', options: [{ text: 'Sim', value: 2 }, { text: 'Não', value: 1 }] },
      },
    },
    languages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'languages',
      label: 'Línguas',
      list: {
        title: { label: 'Título', name: 'title' },
        code: { label: 'Código', name: 'code' },
      },
    },


    sign_up_fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'sign_up_fields',
      label: 'Campos de formulário',
      show: 'title',
      list: [
        {
          label: 'Campo', name: 'fields', type: 'select', options: [
            { text: 'Telefone', label: 'Telefone', value: 'phone' },
            { text: 'Categoria', label: 'Categoria', value: 'category_id' },
            { text: 'Empresa', label: 'Empresa', value: 'company' },
            { text: 'Cargo', label: 'Cargo', value: 'position' },
            { text: 'Data de nascimento', label: 'Data de nascimento', value: 'birth_date' },
            { text: 'Cargo', label: 'Cargo', value: 'position' },
            { text: 'Estado', label: 'Estado', value: 'state' },
            { text: 'Município', label: 'Município', value: 'city' },
            { text: 'Endereço', label: 'Endereço', value: 'address' },
            { text: 'Número', label: 'Número', value: 'address_number' },
            { text: 'CEP', label: 'CEP', value: 'zipcode' },
            { text: 'CRM', label: 'CRM', value: 'crm' },
            { text: 'Extra 1', label: 'Extra 1', value: 'extra_1' },
            { text: 'Extra 2', label: 'Extra 2', value: 'extra_2' },
            { text: 'Extra 3', label: 'Extra 3', value: 'extra_3' },
            { text: 'Extra 4', label: 'Extra 4', value: 'extra_4' },
            { text: 'Extra 5', label: 'Extra 5', value: 'extra_5' },
          ]
        },
        { label: 'Substituir título padrão', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [

            { label: 'Texto', text: 'Texto', value: 'input' },

            { label: 'Seleção', text: 'Seleção', value: 'select' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },
      ]
    },
  };

  const formSchemaUpdate = {
    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'Logo',
    },
    home_logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    background: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background',
      label: 'Fundo',
    },





    ...formSchema,
    popup_status: {
      model: 'selectSimple',
      name: 'popup_status',
      label: 'Popup Ativo?',
      defaultValue: 'no',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    popup_link: { model: 'input', type: 'text', name: 'popup_link', label: 'Link do Popup' },

    popup_images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'popup_images',
      label: 'Imagens do Popup',
      list: [
        { label: 'Imagem', name: 'url' },

        { label: 'Link', name: 'link_url' },

      ],
    },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsAdmin;
