import { Form } from '@unform/web';
import React, { useState } from 'react';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import LeadForm from '../../../../pages/LeadForm';
import api from '../../../../services/api';

const LeadCapture: React.FC<Record<string, any>> = React.memo(({ coupon, redirect }) => {
    const { addToast } = useToast();
    const { setTrackingCupom } = useAuth();
    const [ready, setReady] = useState(false);
    const { removeModal } = useModal();
    const { configuration } = useConfiguration();

    const sendData = (data) => {

        if (!data?.name) {
            addToast({ title: 'Preencha seu nome completo', type: 'error' })
        }
        if (!data?.email) {
            addToast({ title: 'Preencha seu E-mail', type: 'error' })
        }
        if (!data?.phone) {
            addToast({ title: 'Preencha seu Whatsapp', type: 'error' })
        }


        try {
            api.post('subscribe-lead', { ...data, project_id: configuration?.url });

            setTrackingCupom(coupon)
            setReady(true);

            setTimeout(() => {
                removeModal('coupon');

                if (redirect) {
                    window.location.href = redirect;
                }
            }, 3000)

        }
        catch (err) {
            addToast({ title: 'Erro ao enviar dados', type: 'error' })
        }
    }

    if (ready) {
        return <div className='column pad'>
            <h2 style={{ fontSize: '16px', textAlign: 'center', width: '100%', lineHeight: '20px', color: 'rgb(2, 28, 60)' }}>Seu cupom foi aplicado!</h2>
            <p style={{ fontSize: '14px', textAlign: 'center', width: '100%', marginTop: '10px', lineHeight: '18px', color: 'rgb(2, 28, 60)' }}> Você receberá 5% de desconto adicionais após selecionar sua categoria de ingressos</p>

            <h2 style={{ fontSize: '32px', padding: '20px', border: '1px solid #ddd', textAlign: 'center', margin: '15px auto', width: '100%', lineHeight: '36px', color: 'rgb(2, 28, 60)' }}>{coupon}</h2>

        </div>

    }

    return <Form onSubmit={sendData} style={{ padding: '15px' }}>
        <h2 style={{ fontSize: '16px', textAlign: 'center', margin: '15px auto', width: '100%', lineHeight: '20px', color: 'rgb(2, 28, 60)' }}>Preencha o formulário e receba um cupom de desconto!</h2>
        <Input name='name' placeholder='Nome completo' />
        <Input name='email' placeholder='E-mail' />
        <MaskedInput mask='(99) 99999.9999' name='phone' placeholder='Whatsapp' />
        <button style={{ margin: '15px auto', color: 'rgb(2, 28, 60)' }} className='highlightButtonGreenArrow'>Quero o meu cupom</button>
    </Form>


});

export default LeadCapture;