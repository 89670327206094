import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import { FaProductHunt, FaReceipt } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}


const EventsAdmin: React.FC = () => {
  const endpoint = '/events';
  const title = 'Eventos';

  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    contador: { ref: 'contador', column: 'contador', label: 'Contador' },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    sale_start_date: { ref: 'sale_start_date', column: 'sale_start_date', label: 'Data de início das vendas' },

    start_at: { ref: 'start_at', column: 'start_at', label: 'Data do evento' },

    date_text: { ref: 'date_text', column: 'date_text', label: 'Data em texto' },
    image: { ref: 'image', column: 'image', label: 'Foto', show: false },
    status: { ref: 'status', column: 'status', label: 'Ativo?' },
    selected: { ref: 'selected', column: 'selected', label: 'Evento Atual?' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    contador: { model: 'input', type: 'text', name: 'contador', label: 'Contador' },
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },
    email_theme: { model: 'input', type: 'text', name: 'email_theme', label: 'Modelo de e-mail' },

    subscription_limit: { model: 'input', type: 'text', name: 'subscription_limit', label: 'Limite de Vagas' },
    sale_start_date: { model: 'datePickerHour', type: 'text', name: 'sale_start_date', label: 'Início das vendas' },
    start_at: { model: 'datePickerHour', type: 'text', name: 'start_at', label: 'Data do evento' },
    date_text: { model: 'input', type: 'text', name: 'date_text', label: 'Data em texto' },


    event_type: {
      model: 'selectSimple',
      name: 'event_type',
      label: 'Categoria de evento',
      defaultValue: 'Online',
      options: [
        { label: 'Online', value: 'Online' },
        { label: 'Híbrido', value: 'Híbrido' },
        { label: 'Presencial', value: 'Presencial' },
      ]
    },


    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    selected: {
      model: 'selectSimple',
      name: 'selected',
      label: 'Evento Atual?',
      defaultValue: 'Não',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo de evento',
      defaultValue: 'free',
      options: [
        { label: 'Gratuito', value: 'free' },
        { label: 'Pago', value: 'paid' },
      ]
    },
    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto padrão',
      endpoint,
    },

    active_pages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'active_pages',
      label: 'Páginas / Menu',
      list: {
        hash: { label: 'Hash', name: 'hash' },
        title: { label: 'Título', name: 'title' },
        url: { label: 'Link', name: 'url' },
        menu: { label: 'Menu?', name: 'menu', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        signed: { label: 'Logado?', name: 'signed', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        type: { label: 'Tipo', name: 'type', type: 'select', options: [{ text: 'Página principal', value: 'page' }, { text: 'Aba separada', value: 'single_page' }, { text: 'Modal', value: 'modal' }, { text: 'Link externo', value: 'external' }, { text: 'Menu', value: 'menu' }] },
      },
      defaultValue:
        [
          { hash: 'home', url: '', title: 'Home', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'product', url: 'inscricao', title: 'Venda', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signup', url: 'inscricao', title: 'Inscrição', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'program', url: 'programacao', title: 'Programação', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'speakers', url: 'palestrantes', title: 'Palestrantes', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'poster', url: 'poster', title: 'Pôster', menu: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'certificate', url: 'certificados', title: 'Certificados', menu: 'no', active: 'no', signed: 'no', type: 'page' },
        ]
    },
  };


  const formSchemaUpdateProducts = {
    boleto_date: { model: 'datePicker', type: 'text', name: 'boleto_date', label: 'Data de Vencimento dos boletos' },
    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Liberar Boleto',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_cortesy: {
      model: 'selectSimple',
      name: 'allow_cortesy',
      label: 'Liberar Cortesia',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Liberar Cartão de Credito',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_pix: {
      model: 'selectSimple',
      name: 'allow_pix',
      label: 'Liberar Pix',



      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    boleto: {
      model: 'selectSimple',
      name: 'boleto',
      label: 'Vencimento do boleto (dias)',
      default: '7',


      options: [


        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },




      ]

    },
    open_status: {
      model: 'selectSimple',
      name: 'open_status',
      label: 'Ativo?',
      defaultValue: 'open',
      options: [
        { label: 'Aguardando abertura', value: 'waiting' },
        { label: 'Aberto', value: 'open' },
        { label: 'Encerrado', value: 'closed' },
      ]
    },

    sales_limit_type: {
      model: 'selectSimple',
      name: 'sales_limit_type',
      label: 'Limitar Vendas?',
      defaultValue: 'no',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]
    },
    sales_limit: { model: 'input', type: 'text', name: 'sales_limit', label: 'Número máximo de ingressos', default: '100000' },

    product_order: {
      model: 'selectSimple',
      name: 'product_order',
      label: 'Ordem dos Produtos',
      defaultValue: 'desc',
      options: [
        { label: 'Crescente de Preço', value: 'asc' },
        { label: 'Descrescente de Preço', value: 'desc' },
        { label: 'Por posição definida', value: 'position' },
      ]
    },
    product_header: { model: 'richText', type: 'text', name: 'product_header', label: 'Cabeçalho da área de produtos' },
    product_footer: { model: 'richText', type: 'text', name: 'product_footer', label: 'Rodapé da área de produtos' },
    product_success: { model: 'richText', type: 'text', name: 'product_success', label: 'Texto de sucesso' },


  };

  const formSchemaUpdateFields = {
    fields: {
      model: 'jsonInputList',
      type: 'text',
      name: 'fields',
      label: 'Campos',
      show: 'title',
      list: [
        {
          label: 'Campo', name: 'field', type: 'select', options: [
            { label: 'Cargo', text: 'Cargo', value: 'position' },
            { label: 'Empresa / Instituição', text: 'Empresa  / Instituição', value: 'company' },
            { label: 'Categoria', text: 'Categoria', value: 'category_id' },
            { label: 'Sexo', text: 'Sexo', value: 'gender_id' },
            { label: 'Escolaridade', text: 'Escolaridade', value: 'education_id' },
            { label: 'Como soube do evento?', text: 'Como soube do evento?', value: 'know_about' },
            { label: 'Extra 1', text: 'Extra 1', value: 'extra_1' },
            { label: 'Extra 2', text: 'Extra 2', value: 'extra_2' },

          ]
        },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'input' },
        {
          label: 'Tipo', name: 'type', type: 'select', options: [
            { label: 'Estrelas', text: 'Estrelas', value: 'star' },
            { label: '1 a 10', text: '1 a 10', value: 'range' },
            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ]
    },

  };


  const formSchemaMail = {


    mail_header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'mail_header',
      label: 'Cabeçalho de e-mail',
    },

    mail_footer: {
      model: 'uploadImage',
      type: 'upload',
      name: 'mail_footer',
      label: 'Rodapé de e-mail',
    },



  };


  const formSchemaUpdate = {
    thumb: {
      model: 'uploadImage',
      type: 'upload',
      name: 'thumb',
      label: 'Thumb de Capa',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdateProducts,
          validationSchema: {},
          endpoint,
          Icon: FaProductHunt
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaMail,
          validationSchema: {},
          endpoint,
          Icon: FiMail
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdateFields,
          validationSchema: {},
          endpoint,
          Icon: FaReceipt
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsAdmin;
