import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

import ThemeDefinitions from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface AnimatedDivProps {
  visible: boolean;
}

export const Container = styled.div`
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;



  @media (max-width: 740px) {
    padding-top: 0px;
    justify-content: center;
  }

  code {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    z-index: 1000;
  }
`;

export const AnimatedDiv = styled.div<AnimatedDivProps>`
  z-index: 1;
  display: flex;
  flex-direction: row;
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  background: none;
  padding: 0px 0px;
  animation: ${fadeFromLeft} 1s;
  transition: display 0.2s;
  color: #333;

  

  @media (max-width: 1200px) {
  
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

.SelectPaymentMethod{

  div{
    width: 100px;
    height: 100px ;
    border-radius: 7.5px;
    display: flex ;
    align-items: center;
    justify-content: center;
    border: 2px solid #ddd;
    flex-direction: column;

    p{
      font-size: 8px;
      font-weight: bold;
      width: 100%;
      text-align: center;
    }

    svg{
      width: 60px;
      font-size: 60px;
    }
  }

}

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    align-items: center;

    >div{
      width: 100%;
    }

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #333;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#333')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

interface IActive {
  active: boolean
}
export const ActiveButtonItem = styled.div<IActive>`

background: #333;
color: #fff;
padding: 10px;
min-width: 50px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;



>aside{

  padding: 3px;
  font-size: 12px;
  line-height: 14px;

}

>svg{
  font-size: 18px;
  min-width: 30px;
}

${props => props.active && css`
  background: #c3a257;
  color: #fff;
 transform: scale(1.1) ;
 border-radius: 5px;
`}

`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  > button {
    position: relative;
    > img {
      width: 100%;
      max-width: 200px;
    }

    > input {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      opacity: 0.01;
    }
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;
