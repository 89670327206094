import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}     
interface IParams {
  projectHash : string;
  eventHash : string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/speakers';
  const title = 'Palestrantes';
    const {projectHash, eventHash } = useParams<IParams>(); 
    const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    company: { ref: 'company', column: 'company', label: 'Instituição' },
    position: { ref: 'position', column: 'position', label: 'Cargo' },
    category_id: { ref: 'category_id', column: 'category_id', label: 'Categoria' },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    category_id: {
      model: 'selectSimple',
      name: 'category_id',
      label: 'Categoria',
      defaultValue: 'Palestrante',
      options: [
        { label: 'Palestrante', value: 'Palestrante' },
        { label: 'Comissão Científica', value: 'Comissão Científica' },
        { label: 'Diretoria', value: 'Diretoria' },
        { label: 'Apresentação', value: 'Apresentação' },
    
      ],
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título (Dr., Dra.)' },
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    company: { model: 'input', type: 'text', name: 'company', label: 'Instituição' },
    position: { model: 'input', type: 'text', name: 'position', label: 'Cargo' },
    short_description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo Curto' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },

  };

  const formSchemaUpdate = {
   
    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'photo',
    },
    ...formSchema
  };

  const formValidation: Record<string, ValidationSchema> = {
    name: {
      name: 'name',
      type: 'string',
      label: 'Palestrante',
      rules: [{ type: 'required' }],
    },
  };


  
  const config: ISearchComponent = {
    idKey : `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
