import styled from "styled-components";

export const ItemDiv = styled.div`
    
    display: flex;
    position: relative;
    transition: 0.5s all;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &:hover{
        transform: scale(1.01);
>div{
    box-shadow: 0px 0px 25px  rgba(0,250,0,0.9) !important;
}
        >h2{
            border-color:  rgb(0,250,0) !important;
        }
    }

>div{
    transition: 0.5s all;
    align-self: flex-start;
    margin-right: -10px;
    font-weight: bold;
    background-color:  rgb(0,250,0) !important;
box-shadow: 0px 0px 15px  rgba(0,250,0,0.7) !important;
    backdrop-filter : blur(11px);
    font-size: 28px;
    color: rgb(2, 28, 60);
    display: flex ;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
    h2{
        transition: 0.5s all;
        background-color:   #091f7554 !important;
        backdrop-filter : blur(20px);
        margin-top: 30px;
        border-radius: 10px;
        font-size: 14px;
        border: 2px solid #ff011e00;
  color: #fff;
        padding: 30px;
        max-width:500px;
        width: 100%;
    }


`