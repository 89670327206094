import React, { useState } from 'react';
import { FaChevronDown, FaChevronRight, FaWhatsapp } from 'react-icons/fa';
import FadeInOnScroll from '../../../../../../components/FadeInOnScroll';

import ContainerLine from "../../../../../../components/UIComponents/ContainerLine";
import { ContainerLineUI } from "../../../../../../components/UIComponents/ContainerLine/styles";

import { ParagraphUI } from "../../../../../../components/UIComponents/Paragraph/styles";
import { ParagraphHighlightUI, TitleHighlightUI } from '../../../../../../components/UIComponents/TitleHighlight/styles';
import VideoBackground from "../../../../../../components/VideoBackground";
import { urlWebsite } from "../../../../../../config";
import { useAuth } from '../../../../../../hooks/Auth';
import { useModal } from '../../../../../../hooks/Modal';
import { scrollToID } from '../../../../../../utils/scrollTo';
import LeadCapture from '../../../ButtonLeadCapture';
import RenderPageList from '../../modules/RenderList';
import RenderPageImages from "../../modules/RenderPageImages";

const HomeLeftPageTemplate = ({ pageContent, theme }: Record<string, any>) => {
  const { hash } = pageContent;
  const { addModal } = useModal();
  const { activeByModal, setActiveByModal } = useAuth();


  const checkLeadForm = (buttonInfo: Record<string, any>) => {
    setActiveByModal(true);
    addModal({ title: '', theme: 'whiteModalMedium', content: <LeadCapture coupon={buttonInfo?.coupon} /> });
    if (buttonInfo?.id_target) {
      scrollToID(buttonInfo?.id_target)
    }

  }

  return (
    <>

      <ContainerLineUI className=' full-width row-to-column-reverse backgroundImagePreset' id={hash} style={{ background: pageContent.background_image ? `url("${urlWebsite}/${pageContent?.background_image}")` : pageContent?.theme_data?.background ? pageContent?.theme_data?.background : '#fff' }} >


        {pageContent?.theme_data?.hide_side_content !== 'yes' ? <div className='column full-width max-600 gap-sm pad' >
          {pageContent?.theme_data?.show_title !== 'no' ? <FadeInOnScroll><TitleHighlightUI className='hugeTitle' color={pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#fff'} background={pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : ''} style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: pageContent?.title }} /></FadeInOnScroll> : <></>}
          <FadeInOnScroll><ParagraphHighlightUI color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} className='midTitle max-600 text-center' dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} /></FadeInOnScroll>

          {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
            return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
          })}



          {pageContent?.map_link ? <div style={{ width: '100%', maxWidth: '400px', borderRadius: '15px', overflow: 'hidden' }}  > <iframe src={pageContent?.map_link} width="600" height="450" style={{ 'border': 0 }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> </div> : <></>}

          {pageContent?.video ? <VideoBackground path={pageContent?.video} /> : <></>}
          {pageContent?.data_list?.length > 0 ? <FadeInOnScroll><RenderPageList list={pageContent?.data_list} /></FadeInOnScroll> : <></>}
          {pageContent?.buttons?.length > 0 ? <div style={{ margin: '0px auto' }}>
            {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {

              return itemButton?.coupon ? <button onClick={() => checkLeadForm(itemButton)} className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title} {itemButton.class_name?.indexOf('arrow-right') >= 0 ? <FaChevronRight style={{ fontSize: '36px' }} /> : <></>}</button> : <a href={`${itemButton.link}`} > <button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title} {itemButton.class_name?.indexOf('arrow-right') >= 0 ? <FaChevronRight style={{ fontSize: '36px' }} /> : <></>}</button> </a>
            })}
          </div> : <></>}
        </div> : <></>}

        <div className='homeImage' ><img loading="lazy" className='full-width' style={{ maxWidth: '800px' }} src={`${urlWebsite}/${pageContent?.image}`} /></div>
      </ContainerLineUI>
      {/*
      <ContainerLineUI className=' column arrowDown' style={{ justifyContent: 'center', zIndex: 1000 }} >
        <ParagraphUI onClick={() => { scrollToID('palestrantes') }} style={{ color: '#fff', marginBottom: '10px' }}>Saiba mais</ParagraphUI>
        <FaChevronDown className="beat" onClick={() => { scrollToID('palestrantes') }} style={{ cursor: 'pointer', fontSize: '36px', color: '#ddd' }} />


          </ContainerLineUI> */}
    </>
  );


}

export default HomeLeftPageTemplate;