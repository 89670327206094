import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useField, FormHandles } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';
import { Error } from '../styles';
import { useLanguage } from '../../../hooks/Language';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  mask: string;
  mandatory?: boolean;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}
const MaskedInput: React.FC<Props> = ({
  name,
  icon: Icon,
  setCallback = () => { },
  mask,
  mandatory = false,
  formRef,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const { translate } = useLanguage();

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFilled(!!inputRef.current);

    const length = setIsFocus(false);
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <span style={{ color: '#333', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{translate(rest.placeholder)}{mandatory ? <p style={{ display: 'inline-block', color: 'rgb(150,0,0)', fontSize: '11px', marginLeft: '15px', marginRight: '32px' }}>*Obrigatório</p> : <></>}</span>
      {Icon && <Icon />}

      <ReactInputMask
        mask={mask}
        onChange={e => setCallback(e.target.value, formRef)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={translate(rest.placeholder)}
        {...rest}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default MaskedInput;
