import styled, { keyframes } from "styled-components";

const wave = keyframes`
    0% {
        background-color: rgb(0,100,150) ;
    }
    50% {
     
        background-color: #333;
    }
    100% {
 
        background-color: rgb(0,100,150) ;
    }
 `;

const borderWave = keyframes`
0% {
    border: 1px solid #023c54 ;
}
50% {
 
    border: 3px solid #023c54 ;
}
100% {

    border: 1px solid #023c54 ;
}
`;

const rotate = keyframes`
    0% {
        transform:  rotate(0deg);
        gap: 5px;
           
  
       
  
    }
    50% {
        transform:  rotate(180deg);
        gap: 75px;
        
      
       
  
    }
    100% {
 
        transform:   rotate(360deg);
        gap: 5px;
 
           
        }
    }
 `;


export const Loader = styled.div`

 
    width: 15px;
    height: 15px;
    max-width: 15px;
    max-height: 15px;
    background-color: #777;
    border-radius: 25%;
    position: relative;
    box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.5)  
    overflow: hidden;

    padding: 5px;
   
`;

export const Background = styled.div`

position: fixed;
top: 0px;
left: 0px;
right: 0px;
bottom: 0px;
background: #021f42;

width: 100%;
height: 100vh ;

display: flex;
flex-direction: column;
color: #fff;
align-items: center;
justify-content: center;
z-index: 10000000;

h2{
    color: #fff;
    margin-top: 20px;
}

>div{
    display: flex;
 
color: #fff;
align-items: center;
justify-content: center;
gap:  25px;
 
 
width: 300px ;
 
 

 

img{
    width: 350px;
    max-width: 90%;
}
 .loader1 {
  
 }
 .loader2 {
     animation-delay: 0.5s;
 }
 .loader3 {
     animation-delay: 1s;
 }
 .loader4 {
     animation-delay: 1.5s;
 }

`