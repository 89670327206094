import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}


const EventsPagesAdmin: React.FC = () => {
  const endpoint = '/events-pages';
  const title = 'Páginas Avulsas';

  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },

    title: { ref: 'title', column: 'title', label: 'Título' },
    url: { ref: 'url', column: 'url', label: 'URL' },
    description: { ref: 'description', column: 'description', label: 'Descritivo', show: false },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    title: { model: 'richText', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    video: { model: 'input', type: 'text', name: 'video', label: 'Link de Vídeo' },
    map_link: { model: 'input', type: 'text', name: 'map_link', label: 'Link do mapa' },
    start_at: { model: 'datePickerHour', type: 'text', name: 'start_at', label: 'Data de referência' },
    orientation: {
      model: 'selectSimple',
      name: 'orientation',
      label: 'Orientação',
      defaultValue: 'line',
      options: [
        { label: 'Linha', value: 'line' },
        { label: 'Coluna', value: 'column' },
      ]
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'files',
      label: 'Arquivos',
      list: [
        { label: 'Arquivo', name: 'url' },
        { label: 'Título', name: 'title' },


      ],

    },
    buttons: {
      model: 'jsonInputList',
      type: 'text',
      name: 'buttons',
      label: 'Botões',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Link', name: 'link', type: 'input' },
        { label: 'Tipo de botão', name: 'class_name', type: 'input' },
        { label: 'Cupom', name: 'coupon', type: 'input' },
        { label: 'ID de rolagem', name: 'id_target', type: 'input' },
      ],
    },
    data_list: {
      model: 'jsonInputList',
      type: 'text',
      name: 'data_list',
      label: 'Lista de Dados',
      show: 'title',

      list: [
        { label: 'Chamada', name: 'subtitle', type: 'input' },
        { label: 'Título', name: 'title', type: 'input' },

        { label: 'Texto', name: 'text', type: 'richText' },
        {
          label: 'Listagem', name: 'list', type: 'jsonList', show: ['title'], list: [
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Bold', name: 'bold', type: 'select', options: [{ label: 'Sim', value: 'yes' }, { label: 'Não', value: 'no' }] },
          ]
        },
        { label: 'Link de vídeo', name: 'video', type: 'input' },
        { label: 'Link de redirecionamento', name: 'url', type: 'input' },
        { label: 'Data para cronometro', name: 'date', type: 'input' },

        { label: 'Valor de:', name: 'price_before', type: 'currency' },
        { label: 'Valor por:', name: 'price', type: 'currency' },
      ]
    },
    images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'images',
      label: 'Imagens',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Classe', name: 'class_name' },
        { label: 'Link', name: 'link_url' },

      ],
    },
    theme_data: {
      model: 'jsonList',
      type: 'text',
      name: 'theme_data',
      label: 'Personalização',
      list: [
        { label: 'Fundo', name: 'background' },
        { label: 'Cor da linha', name: 'line_color' },
        { label: 'Cor do título', name: 'title_color' },
        { label: 'Fundo do título', name: 'title_background' },
        { label: 'Cor do Texto', name: 'text_color' },
        { label: 'Template de imagens', name: 'image_template' },
        { label: 'Esconder bloco lateral?', name: 'hide_side_content' },
        { label: 'Template da página', name: 'template' },
      ],
    },
  };

  const formSchemaUpdate = {
    background_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background_image',
      label: 'Fundo',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto',
    },
    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsPagesAdmin;
