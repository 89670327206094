import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';


import { urlWebsite } from '../../../../../../config';
import IframePlayer from '../../../../../../components/UIComponents/IframePlayer';
import VideoBackground from '../../../../../../components/VideoBackground';
import { useModal } from '../../../../../../hooks/Modal';
import LeadCapture from '../../../ButtonLeadCapture';
import { scrollToID } from '../../../../../../utils/scrollTo';
import { FaChevronRight } from 'react-icons/fa';
import { useAuth } from '../../../../../../hooks/Auth';


const SinglePageLeftTemplate: React.FC<Record<string, any>> = ({ theme, pageContent }) => {
  const { hash } = pageContent;

  const { addModal } = useModal();
  const { activeByModal, setActiveByModal } = useAuth();
  const checkLeadForm = (buttonInfo: Record<string, any>) => {
    setActiveByModal(true)
    addModal({ title: '', theme: 'whiteModalMedium', content: <LeadCapture coupon={buttonInfo?.coupon} /> });
    if (buttonInfo?.id_target) {
      scrollToID(buttonInfo?.id_target)
    }

  }


  return (
    <>

      <Container style={{ flexDirection: pageContent?.orientation === 'column' ? 'column' : 'row', background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} >

        {pageContent?.images && pageContent?.images?.length > 0 && pageContent?.images?.map((image: Record<string, any>) => {

          return <img style={{ maxWidth: '450px' }} src={`${urlWebsite}/${image?.url}`} />

        })}

        <div className='content' style={{ maxWidth: '500px' }}  >
          <div className='column-start' style={{ maxWidth: '500px' }}>
            <h2 dangerouslySetInnerHTML={{ __html: pageContent?.title }} style={{ fontSize: '26px', color: pageContent?.theme_data?.title_color ? pageContent?.theme_data?.title_color : '#333', background: pageContent?.theme_data?.title_background ? pageContent?.theme_data?.title_background : 'none', width: 'auto', display: 'inline-block', }} />
            <Paragraph style={{ background: pageContent?.theme_data?.background ? pageContent?.theme_data?.background : pageContent?.image ? `url("${urlWebsite}/${pageContent?.image}")` : '#fff' }} color={pageContent?.theme_data?.text_color ? pageContent?.theme_data?.text_color : '#333'} dangerouslySetInnerHTML={{ __html: pageContent?.description || '' }} />
          </div>
          {pageContent?.files && pageContent?.files.map((item: Record<string, any>) => {
            return <a href={`${urlWebsite}/${item.url}`} target="_blank"><button className='defaultButton'>{item.title}</button></a>
          })}

          {pageContent?.buttons?.length > 0 ? <div style={{ margin: '20px auto' }}>
            {pageContent?.buttons && pageContent?.buttons.map((itemButton: Record<string, any>) => {
              return itemButton?.coupon ? <button onClick={() => checkLeadForm(itemButton)} className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title} {itemButton.class_name?.indexOf('arrow-right') >= 0 ? <FaChevronRight style={{ fontSize: '36px' }} /> : <></>}</button> : <a href={`${itemButton.link}`} > <button className={itemButton.class_name || 'greenButton2 shadow'} style={{ background: theme?.button_background, color: theme?.button_color }}>{itemButton.title} {itemButton.class_name?.indexOf('arrow-right') >= 0 ? <FaChevronRight style={{ fontSize: '36px' }} /> : <></>}</button> </a>
            })}
          </div> : <></>}
        </div>


        {pageContent?.video ? <div className='imageHolder'><VideoBackground path={pageContent?.video} /> </div> : <></>}


      </Container>
    </>
  );
};
export default SinglePageLeftTemplate;
