import styled from 'styled-components';
import { defaultTheme } from '../../config';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1000000000;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 1000000000;

  .ListIcons {
    > button {
      margin: 3px;
      opacity: 0.7;
      transition: 0.5s all;
      &:hover {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1200px) {
    position: fixed;
  }



  > div {
    overflow-y: auto;
    padding: 40px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }
  }
`;
