import styled, { keyframes } from 'styled-components';


const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Wrapper = styled.div`

width: 100% ;
display: flex ;
align-items: flex-start;
justify-content:center;

.modal_summit{
  .speakerPhotoDiv {
    border-radius: 50%;
    width: 180px !important ;
    height: 180px !important ;
    min-width: 180px !important ;
    min-height: 180px !important ;
    overflow: hidden ;
    background-position : top center ;
    margin: 0px auto;
    text-align: center;

    >img{
      width: 105% !important;
      transform: translateX(-2.5%) ;
      height: auto;
      padding-bottom: 105%;
       
    }
  }
div {
  h2, p, h3, strong {
    color: rgb(0,14,64) !important;
  }
}

}

.summit {
  background: none !important;
  box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;

  h2, p, h3, strong {
    color: #fff !important;
  }
  >div{
    >div{
    >div{
      background: none !important;
      box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
    }
    }
  }

  .speakerPhotoDiv {
    border-radius: 50%;
    width: 180px !important ;
    height: 180px !important ;
    min-width: 180px !important ;
    min-height: 180px !important ;
    overflow: hidden ;
    background-position : top center ;
    margin: 0px auto;
    text-align: center;
    >div{
      width: 105% !important;
      transform: translateX(-2.5%) ;
      height: auto;
      padding-bottom: 105%;
       
    }
  }
}



.summit2 {
  background: none !important;
  box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;


  >div{
    >div{
    >div{
      background: none !important;
      box-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
    }
    }
  }

.title{
  h2, p, h3, strong {
    color: #fff !important;
  }
}

.contentSpeaker{
  h2, p, h3, strong {
    color: #fff !important;
  }
}
.shadow{
 

  .speakerModalDiv{
  h2, p, h3, strong {
    color: #333 ;
  }
}
  >.speakerPhotoDiv {
    border-radius: 50%;
    width: 150px !important ;
    height: 150px !important ;
    min-width: 150px !important ;
    min-height: 150px !important ;
    overflow: hidden ;
    background-position : top center ;
    margin: 0px auto;
    text-align: center;
    >div{
      width: 105% !important;
   
      height: auto;
      padding-bottom: 105%;
       
    }
  }
}
}

`;

export const Container = styled.div<IBackground>`
  min-height: 60vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
//  background: #fff;
  color: #555;
  padding: 10px 10px;
  width: 100%;
  max-width: 1400px;
  margin: 20px auto;
  border-radius: 15px;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .speakerPhotoDiv{
    width:100%;
    margin: 0px auto;
    text-align: center;
  }

  > div.p50 {
    padding: 20px;

    @media (max-width: 1200px) {
      padding: 5px;
    }
  }
  > div {
    > h2 {
      font-size: 16px;
      padding: 0;

      color: #ffba1c;
      text-align: left;

      @media (max-width: 1200px) {
        text-align: center;
      }
    }

    > div {
      > p,
      > strong,
      > span {
        color: #fff;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 0px 20px;
  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;

    > div {
      width: 210px;
      max-width: 280px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > h2 {
        font-size: 16px;

        color: #fff;
        text-align: center;
      }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(100, 02, 08);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(100, 02, 08);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 100%;
    height: 5px;
    background: #ffba1c;
    margin: 10px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(100, 02, 08);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(100, 02, 08);
      }

      > p {
        font-size: 14px;

        color: rgb(100, 02, 08);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(100, 02, 08);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  padding: 100px 5%;
  width: 100%;
  max-width: 100%;
  margin: auto;
  z-index:200;

  button{
    position: relative;
    z-index: 200;
  }

  @media (max-width: 1200px) {
    padding: 0px 20px;
    flex-direction: column;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

  .p600 {
    max-width: 800px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(100, 02, 08);
    }

    >h2{
      font-size: 22px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;

      color: rgb(0, 100, 150);
    }

    > a {
      z-index: 2;
       } button {
         margin: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(100, 02, 08);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(100, 02, 08);
      }
    }
  }
  }

  .ProgramGroup {
    display: flex;

    @media (max-width: 1200px) {

      >button {
        max-width: 32%;
        padding: 10px;
        margin: 5px;
      }
    }

  }
`;

interface IEvent {
  active: boolean;
}

export const Evento = styled.div<IEvent>`
  background: #fff;
  color: #333;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 30px 20px;
  z-index: 200;
  margin: 10px 0;
  position: relative;
  animation: ${fadeFromLeft} 0.5s;

  > button {
    max-width: 10px;
    width: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 10px;
    color: rgb(100, 02, 08) !important;
    background: none !important;
    border: 0px;
    transition: 0.2s all;
    z-index: 202;

    &:hover {
      color: rgb(0, 100, 200);
    }
  }

  > div.evento-horario {
  }

  .evento-title {
    font-size: 20px;
    @media (max-width: 1200px) {
      font-size: 18px;
    }
  }

  .eventoExtra {
    display: flex;
    flex-direction: column;
    padding: 20px;
    animation: ${fadeFromLeft} 0.5s;
    > ul {
      margin: 10px 0px;
      > li {
        margin-left: 20px;
      }
    }
  }
  > h2 {
    font-size: 18px;
  }
`;
