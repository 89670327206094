import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import template from './template/BlockRender';
import eventWithDinnerReport from './modules/DisplayReport';
import cartOffReport from './modules/CartOffReport';
import { FinanceReportByProjectModule } from './modules/CheckFinance';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

const EventsAdmin: React.FC = () => {
  const endpoint = '/projects';
  const title = 'Projetos';

  const { projectHash, eventHash } = useParams<IParams>();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    url: { ref: 'url', column: 'url', label: 'URL' },
    title: { ref: 'title', column: 'title', label: 'Nome do evento' },
    color: { ref: 'color', column: 'color', label: 'Cor do Projeto' },
    description: { ref: 'description', column: 'description', label: 'Nome do evento' },
    image: { ref: 'image', column: 'image', label: 'Capa de Seleção', show: false },
    data_image: { ref: 'data_image', column: 'data_image', label: 'Imagem da Dasa', show: false },
    logo: { ref: 'logo', column: 'logo', label: 'Logo', show: false },
    background: { ref: 'background', column: 'background', label: 'Fundo', show: false },
    background_color: { ref: 'background_color', column: 'background_color', label: 'Cor de Fundo', show: false },

    next_date: { ref: 'next_date', column: 'next_date', label: 'Próxima Data', show: false },
    upper_text: { ref: 'upper_text', column: 'upper_text', label: 'Texto superior da Capa', show: false },
    header_image: { ref: 'header_image', column: 'header_image', label: 'Imagem de capa', show: false },
    lower_text: { ref: 'lower_text', column: 'lower_text', label: 'Texto inferior da Capa', show: false },
    header_video: { ref: 'header_video', column: 'header_video', label: 'Vídeo', show: false },
    fixed_image_header: { ref: 'fixed_image_header', column: 'fixed_image_header', label: 'Imagem de fundo Fixa', show: false },
    current_event_id: {
      ref: 'current_event_id',
      column: 'current_event_id_response',
      includeColumn: 'title',
      label: 'Evento atual',
      filter: true,
    },

    status_id: {
      ref: 'status_id',
      column: 'status_id_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },




    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do evento' },

    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    distributed_users: {
      model: 'selectSimple',
      name: 'distributed_users',
      label: 'Cadastro único',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },
    have_password: {
      model: 'selectSimple',
      name: 'have_password',
      label: 'Solicitar senha?',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },
    sign_in_type: {
      model: 'selectSimple',
      name: 'sign_in_type',
      label: 'Tipo de autenticação',
      defaultValue: 'email',
      options: [
        { label: 'E-mail', value: 'email' },
        { label: 'CPF', value: 'cpf' },
      ]

    },
  };

  const formSchemaUpdate = {
    logo_main: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo_main',
      label: 'Logo principal (padrão Realização Encontro Digital)',
    },
    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'Logo',
    },
    logo_lower: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo_lower',
      label: 'Logo da Sala Verde',
    },
    logo_lower_size: { model: 'input', type: 'text', name: 'logo_lower_size', label: 'Tamanho da logo da sala verde em px' },
    logo_adicional: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo_adicional',
      label: 'Logo Adicional',
    },
    logo_adicional2: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo_adicional2',
      label: 'Logo Adicional 2',
    },
    background: {
      model: 'uploadImage',
      type: 'upload',
      name: 'background',
      label: 'Fundo',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Foto de Seleção',
    },
    data_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'data_image',
      label: 'Imagem da Data',
    },
    header_image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'header_image',
      label: 'Imagem de capa'
    },
    fixed_image_header: {
      model: 'uploadImage',
      type: 'upload',
      name: 'fixed_image_header',
      label: 'Imagem de fundo Fixa'
    },
    home_image_is_full: {
      model: 'selectSimple',
      name: 'home_image_is_full',
      label: 'Imagem de capa é Full?',
      defaultValue: '1',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    color: { model: 'input', type: 'text', name: 'color', label: 'Cor do projeto' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'descritivo' },
    program_title: { model: 'input', type: 'text', name: 'program_title', label: 'Título da Programação' },
    program_subtitle: { model: 'input', type: 'text', name: 'program_subtitle', label: 'Subtítulo da Programação' },
    upper_text: { model: 'input', type: 'text', name: 'upper_text', label: 'Texto superior da Capa' },

    lower_text: { model: 'input', type: 'text', name: 'lower_text', label: 'Texto inferior da Capa' },
    header_video: { model: 'input', type: 'text', name: 'header_video', label: 'Link do Vídeo da Capa' },

    next_date: { model: 'datePickerHour', type: 'text', name: 'next_date', label: 'Próxima data' },

    background_color: { model: 'input', type: 'text', name: 'background_color', label: 'Cor de Fundo' },
    current_event_id: {
      alias: 'current_event_id',
      model: 'selectApi',
      name: 'current_event_id',
      label: 'Evento atual',
      endpoint,
    },
    status_id: {
      alias: 'status_id',
      model: 'selectApi',
      name: 'status_id',
      label: 'status',
      endpoint,
    },

    theme_data: {
      model: 'jsonList',
      type: 'text',
      name: 'theme_data',
      label: 'Temática',
      list: [
        { label: 'Fundo Primário', name: 'primary_background' },
        { label: 'Cor Primária', name: 'primary_color' },
        { label: 'Fundo secundária', name: 'secondary_background' },
        { label: 'Cor secundária', name: 'secondary_color' },
        {
          label: 'Página - Modelo', name: 'page-theme', type: 'select', options: [

            { label: 'Padrão', value: 'default' },
            { label: 'Single Page', value: 'single_page' },
            { label: 'Sidebar', value: 'sidebar' },

          ]
        },
        {
          label: 'Programação Modelo', name: 'program-theme', type: 'select', options: [

            { label: 'Padrão', value: 'default' },
            { label: 'Clean', value: 'clean' },

          ]
        },
        {
          label: 'Mostrar Cabeçalho da Programação?', name: 'program_header', type: 'select', default: 'yes', options: [

            { label: 'Sim', value: 'yes' },
            { label: 'Não', value: 'no' },

          ]
        },
        {
          label: 'Modelo de Programação', name: 'program_list', type: 'select', default: 'formatted_date', options: [

            { label: 'Data', value: 'formatted_date' },
            { label: 'Categoria', value: 'category' },
            { label: 'Nome da Sala', value: 'room_name' },
          ]
        },
        { label: 'Mostrar ícones de redes sociais?', name: 'show_social_icons' },
        { label: 'Cor secundária', name: 'secondary_color' },
        { label: 'Classe da aba palestras', name: 'speakers_className' },
        { label: 'Tamanho máximo aba palestrantes', name: 'speakers_max_size' },
        { label: 'Mostrar cabeçalho de pesquisa da sala?', name: 'show_room_header' },
        { label: 'Mostrar Área de Chat da Sala', name: 'show_room_chat' },
        { label: 'Mostrar Próximos Vídeos na sala', name: 'show_room_next_videos' },
        { label: 'Texto para a área de certificados', name: 'certificate_text' },

      ],
    },

    active_pages: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'active_pages',
      label: 'Páginas / Menu',
      list: {
        hash: { label: 'Hash', name: 'hash' },
        title: { label: 'Título', name: 'title' },
        url: { label: 'Link', name: 'url' },
        menu: { label: 'Menu?', name: 'menu', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        button: { label: 'Botão', name: 'button', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        class_name: { label: 'Tipo de botão', name: 'class_name', type: 'select', options: [{ text: 'Normal', value: 'defaultButton' }, { text: 'Alternativo', value: 'defaultButtonReverse' }] },
        active: { label: 'Ativo?', name: 'active', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        signed: { label: 'Logado?', name: 'signed', type: 'select', options: [{ text: 'Sim', value: 'yes' }, { text: 'Não', value: 'no' }] },
        type: { label: 'Tipo', name: 'type', type: 'select', options: [{ text: 'Página principal', value: 'page' }, { text: 'Aba separada', value: 'single_page' }, { text: 'Modal', value: 'modal' }, { text: 'Link externo', value: 'external' }, { text: 'Menu', value: 'menu' }] },
      },
      defaultValue:
        [
          { hash: 'home', url: '', title: 'Home', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'product', url: 'inscricao', title: 'Venda', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signup', url: 'inscricao', title: 'Inscrição', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'program', url: 'programacao', title: 'Programação', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'speakers', url: 'palestrantes', title: 'Palestrantes', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'poster', url: 'poster', title: 'Pôster', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'certificate', url: 'certificados', title: 'Certificados', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'files ', url: 'arquivos-de-apoio', title: 'Arquivos de apoio', menu: 'no', class_name: 'defaultButton', button: 'no', active: 'no', signed: 'no', type: 'page' },
          { hash: 'signin', url: 'login', title: 'Acessar', menu: 'yes', class_name: 'defaultButton', button: 'yes', active: 'yes', signed: 'no', type: 'menu' },
        ]
    },
    video_type: {
      model: 'selectSimple',
      name: 'video_type',
      label: 'Formato dos vídeos',
      defaultValue: 'default',
      options: [
        { label: 'Padrão', value: 'default' },
        { label: 'Com separador', value: 'separated' },
      ]
    },
    video_display: {
      model: 'selectSimple',
      name: 'video_display',
      label: 'Formato dos vídeos',
      defaultValue: 'list',
      options: [
        { label: 'Grid', value: 'list' },
        { label: 'Abrir direto', value: 'video' },
      ]
    },
    video_display_hash: { model: 'input', type: 'text', name: 'video_display_hash', label: 'Hash do Vídeo de abertura' },


    room_list_template: {
      model: 'selectSimple',
      name: 'room_list_template',
      label: 'Template da Sala',
      defaultValue: 'default',
      options: [
        { label: 'Padrão', value: 'default' },
        { label: 'Biblioteca', value: 'library' },
        { label: 'Versão Científico', value: 'cientific' },
      ]
    },

    exclusive: {
      model: 'input',
      name: 'exclusive',
      label: 'Adicionar exclusividade de acesso',
      defaultValue: '',

    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Permitir Cartão de crédito',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },
    allow_pix: {
      model: 'selectSimple',
      name: 'allow_pix',
      label: 'Permitir Pix',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },
    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Permitir Boleto',
      defaultValue: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]

    },

    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),

      (lineData: Record<string, any>) => eventWithDinnerReport({ lineData }),
      (lineData: Record<string, any>) => cartOffReport({ lineData }),
      (lineData: Record<string, any>) =>
        FinanceReportByProjectModule({
          lineData,

        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} renderFunction={template} />
    </Container>
  );
};

export default EventsAdmin;
